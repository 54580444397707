import React from 'react'
import AppSeo, { imageUrlBuilder } from '../../../../modules/AppSeo/AppSeo'
import FrontHeaderV2 from '../../../Shared/FrontHeader/FrontHeaderV2'
import FrontFooter from '../../../Shared/FrontFooter/FrontFooter'
// import Slider from "react-slick";
import Accordion from 'react-bootstrap/Accordion';
import Slider from 'react-slick';

const VoletRoulant = () => {
    var settings = {
        dots: true,
        infinite: true,
        autoPlay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    };

    return (
        <div className="page app-component-container">
            <AppSeo 
                title="Serrurier Montpellier | Prix fixé à l'avance - 24h/24h"
                author="Sadio Sangharé" 
                content="Serrurier Montpellier | Prix fixé à l'avance - 24h/24h depuis 2010 ✔️Ouverture de porte 90€ ✔️Urgence 7j/7 ✔️Devis Gratuit ✔️ Agréé Assurance ⭐ 4.7/5 ☆ sur Google" 
                image="/images/appImages/seo-image.png" 
            />


            <FrontHeaderV2 />
            <div className="page-content-container">
                <section id="page-cover" className="page-cover-container volet-roulant">
                    <div className="homepage-cover-resume-container">
                        <h1 className="oh-page-cover-title">Réparation volet roulant</h1>
                        <h2 className="oh-page-title-resume">Votre spécialiste en réparation de volet roulant manuel ou motorisé au meilleur prix.</h2>
                    </div>
                    <div className="page-background-overlay"></div>
                </section>

                <section className="container oh-app-page-container">
                    <div className="service-type-content-body">
                        <div className="row service-type-row">
                            <div className="col-lg-3 service-type-col">
                                <div className="service-type-card-container">
                                    <img src="https://accord-assistance.fr/wp-content/uploads/2021/12/reparateur-volet-roulant-toulouse.svg" alt="réparateur volet roulant montpellier" className="service-type-image-icon" />
                                    <h3 className="service-type-card-title">Réparation volet roulant</h3>
                                    <div className="service-type-card-body">
                                        <div className="service-type-listing-container">
                                            <ul className="service-type-listing">
                                                <li>Remplacement manivelles</li>
                                                <li>Sangle, boîtier, coulisses</li>
                                                <li>Tablier, axe</li>
                                                <li>Lames cassées / tordues</li>
                                                <li>Remplacement inverseur</li>
                                                <li>Manivelle tournant dans le vide</li>
                                                <li>Remplacement treuil</li>
                                                <li>Tablier du volet roulant est bloqué et ne monte plus ou ne descend plus</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 service-type-col">
                                <div className="service-type-card-container">
                                    <img src="https://accord-assistance.fr/wp-content/uploads/2021/12/installation-volet-roulant-toulouse.svg" alt="installation volet roulant montpellier" className="service-type-image-icon" />
                                    <h3 className="service-type-card-title">Déblocage volet roulant</h3>
                                    <div className="service-type-card-body">
                                        <div className="service-type-listing-container">
                                            <ul className="service-type-listing">
                                                <li>Volet roulant bloqué</li>
                                                <li>Problème de manivelle</li>
                                                <li>Alignement des rails</li>
                                                <li>Lame cassée</li>
                                                <li>Problème moteur</li>
                                                <li>Lames qui ne s’enroulent plus dans le caisson</li>
                                                <li>Volet coincé en ouverture ou fermeture</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 service-type-col">
                                <div className="service-type-card-container">
                                    <img src="https://accord-assistance.fr/wp-content/uploads/2021/12/motorisation-volet-roulant-toulouse.svg" alt="motorisation volet roulant montpellier" className="service-type-image-icon" />
                                    <h3 className="service-type-card-title">Motorisation volet roulant</h3>
                                    <div className="service-type-card-body">
                                        <div className="service-type-listing-container">
                                            <ul className="service-type-listing">
                                                <li>Centralisation des volets</li>
                                                <li>Raccordement électrique</li>
                                                <li>Remplacement moteur</li>
                                                <li>Installation moteur</li>
                                                <li>Moteur qui ne fonctionne plus</li>
                                                <li>Programmation et réglage de télécommande</li>
                                                <li>Motorisation tubulaire, filaire ou radio</li>
                                                <li>Moteur volet roulant défectueux</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 service-type-col">
                                <div className="service-type-card-container">
                                    <img src="https://accord-assistance.fr/wp-content/uploads/2021/12/reparation-volet-roulant.svg" alt="réparation volet roulant montpellier" className="service-type-image-icon" />
                                    <h3 className="service-type-card-title">Installation nouveau volet</h3>
                                    <div className="service-type-card-body">
                                        <div className="service-type-listing-container">
                                            <ul className="service-type-listing">
                                                <li>Volet roulant sur mesure</li>
                                                <li>Volet roulant PVC</li>
                                                <li>Volet roulant aluminium</li>
                                                <li>Volet battant bois</li>
                                                <li>Volet anti-effraction</li>
                                                <li>Coulissant, battant, pliant</li>
                                                <li>Volet roulant monobloc</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="service-textual-container">
                        <div className="service-textual-content-body">
                            <div className="row promo-row">
                                <div className="col-lg-3 promo-image-col">
                                    <div className="promo-image-container">
                                        <img src="https://accord-assistance.fr/wp-content/uploads/2021/09/shutterstock_2038327535-1.jpg" alt="Serruriers de Montpellier pas cher" className="promo-image" />
                                    </div>
                                </div>
                                <div className="col-lg-9 promo-text-col">
                                    <div className="promo-text-container">
                                        <h4 className="promo-text-title">Besoin d'un dépannage volet roulant 24h/24, 7j/7 ?</h4>
                                        <h5 className="promo-text-second-title">Les Serruriers de Montpellier votre spécialiste en réparation de volet !</h5>
                                        <div className="promo-text-content-body">
                                            <p>Un problème de volet roulant ne doit jamais durer dans le temps, c’est pourquoi nos réparateurs de volets se déplacent et vous assurent le dépannage de votre volet tous les jours 24h/24</p>
                                            <p>Problème d’ouverture, volet bloqué, remplacement de manivelle, ou de mécanisme ? Nos experts interviennent rapidement pour vous dépanner et vous mettre en sécurité. L’avantage majeur d’un volet est de sécuriser vos fenêtres et de retarder l’effraction, c’est pourquoi il est nécessaire de dépanner à temps votre volet. </p>
                                            <p>Avec les Serruriers de Montpellier un diagnostic est fait avant tout devis afin de trouver la solution la plus adaptée à votre domicile.</p>
                                        </div>
                                        <div className="service-promo-with-slide-slider-container">
                                            <div className="reactif-slide-container"> 
                                                <Slider {...settings}>
                                                    <div className="reactif-infos-slide-item-container">
                                                        <div className="reactif-infos-slide-item-content">
                                                            <img decoding="async" 
                                                                src="https://accord-assistance.fr/wp-content/uploads/2022/01/serrurier-agree-assurance.svg" 
                                                                className="reactif-infos-slide-item-icon" 
                                                                alt="serrurier agréé par les assurances" 
                                                            />
                                                            <div className="reactif-infos-slide-item-textual-container">
                                                                <h4 className="reactif-infos-slide-item-title mb-2">Tarifs compétitifs agréés assurances</h4>
                                                                <div className="reactif-infos-slide-item-text">
                                                                    <p>
                                                                        Notre grille tarifaire est validée par de nombreuses compagnies d’assurances. Tous nos tarifs incluent le déplacement, la main d’œuvre et les fournitures.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="reactif-infos-slide-item-container">
                                                        <div className="reactif-infos-slide-item-content">
                                                            <img decoding="async" 
                                                                src="https://accord-assistance.fr/wp-content/uploads/2022/01/serrurier-urgence.svg" 
                                                                className="reactif-infos-slide-item-icon" 
                                                                alt="serrurier agréé par les assurances" 
                                                            />
                                                            <div className="reactif-infos-slide-item-textual-container">
                                                                <h4 className="reactif-infos-slide-item-title mb-2">Contact client permanent</h4>
                                                                <div className="reactif-infos-slide-item-text">
                                                                    <p>
                                                                        Nous sommes là pour vous assister par téléphone de la prise de rendez-vous jusqu’à la réalisation des travaux, afin de répondre à toutes vos questions.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="reactif-infos-slide-item-container">
                                                        <div className="reactif-infos-slide-item-content">
                                                            <img decoding="async" 
                                                                src="https://accord-assistance.fr/wp-content/uploads/2022/01/serrurier-devis-gratuit.svg" 
                                                                className="reactif-infos-slide-item-icon" 
                                                                alt="serrurier devis gratuit sans engagement" 
                                                            />
                                                            <div className="reactif-infos-slide-item-textual-container">
                                                                <h4 className="reactif-infos-slide-item-title mb-2">Devis gratuit</h4>
                                                                <div className="reactif-infos-slide-item-text">
                                                                    <p>
                                                                    Nous intervenons chez vous pour établir un devis gratuit sans engagement en tenant compte des spécificités de votre domicile et de votre budget.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="reactif-infos-slide-item-container">
                                                        <div className="reactif-infos-slide-item-content">
                                                            <img decoding="async" 
                                                                src="https://accord-assistance.fr/wp-content/uploads/2022/01/serrurier-intervention.svg" 
                                                                className="reactif-infos-slide-item-icon" 
                                                                alt="serrurier intervention 7j/7 24h/24" 
                                                            />
                                                            <div className="reactif-infos-slide-item-textual-container">
                                                                <h4 className="reactif-infos-slide-item-title mb-2">Disponible 7j/7 24h/24</h4>
                                                                <div className="reactif-infos-slide-item-text">
                                                                    <p>
                                                                    Notre équipe de serruriers menuisiers est disponible tous les jours de la semaine, y compris les jours fériés, 24h/24.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Slider> 
                                            </div>
                                            {/*  */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="service-volet-type-container">
                        <div className="service-volet-type-content-body">
                            <div className="service-volet-question-container">
                                <div className="service-volet-question-row">
                                    <h4 className="servicevolet-question-title">Sur quelles pannes de volet intervenons-nous ?</h4>
                                    <div className="service-volet-question-text-container">
                                        <p>Les Serruriers de Montpellier est votre partenaire de confiance spécialisé dans la réparation de volets roulants. Nos réparateurs  interviennent rapidement et efficacement pour résoudre tous types de pannes, que ce soit sur des volets manuels ou motorisés. </p>
                                    </div>
                                </div>
                                <div className="row service-volet-response-row">
                                    <div className="service-volet-response-col col-lg-9">
                                        <div className="service-volet-response-content-body">
                                            <h5 className="service-volet-response-title">Problèmes mécaniques sur volet roulant</h5>
                                            <div className="service-volet-response-listing-container">
                                                <ul className="service-volet-response-listing">
                                                    <li>Tablier bloqué</li>
                                                    <li>Lames abîmées ou déformées</li>
                                                    <li>Manivelle qui tourne dans le vide</li>
                                                    <li>Réparation volet manuel à sangle</li>
                                                    <li>Volet roulant désaxé</li>
                                                    <li>Volet roulant à réajuster</li>
                                                    <li>Dysfonctionnements dans le mécanisme de montée et de descente du volet</li>
                                                    <li>Roulements usés</li>
                                                    <li>Ressorts cassés</li>
                                                    <li>Problèmes de sangle ou de treuil</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="service-volet-response-image-col col-lg-3">
                                        <div className="service-volet-image-content-body">
                                            <img src="https://accord-assistance.fr/wp-content/uploads/2024/04/reparation-volet-roulant-manuel.jpg" alt="réparation volet roulant à montpellier" />
                                        </div>
                                    </div>
                                </div>

                                <div className="row service-volet-response-row">
                                    <div className="service-volet-response-col col-lg-9">
                                        <div className="service-volet-response-content-body">
                                            <h5 className="service-volet-response-title">Problèmes électriques sur volet rouant</h5>
                                            <div className="service-volet-response-listing-container">
                                                <ul className="service-volet-response-listing">
                                                    <li>Panne électrique : Le moteur du volet peut subir une panne électrique, causée par des problèmes de câblage, des fusibles grillés, un disjoncteur déclenché ou une défaillance du moteur lui-même.</li>
                                                    <li>Blocage mécanique : Blocages mécaniques, lames coincées, roulements bloqués ou des obstacles physiques empêchant le mouvement du volet.</li>
                                                    <li>Problèmes de programmation : Réglages incorrects ou de dysfonctionnements du système de commande.</li>
                                                    <li>Défaillance des télécommandes : Problèmes de signal, de synchronisation ou dysfonctionnement des piles.</li>
                                                    <li>Décalibration du moteur : Problèmes de synchronisation entre le moteur et le volet, provoquant un fonctionnement irrégulier ou incorrect.</li>
                                                    <li>Usure des composants : Les composants, tels que les engrenages, les courroies et les interrupteurs, peuvent s’user avec le temps, ce qui peut entraîner des problèmes de fonctionnement.</li>
                                                 
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="service-volet-response-image-col col-lg-3">
                                        <div className="service-volet-image-content-body">
                                            <img src="https://accord-assistance.fr/wp-content/uploads/2024/04/reparation-volet-roulant-motorise.png" alt="réparation volet roulant à montpellier" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="why-choose-container">
                        <div className="why-choose-content-body">
                            <h2 className="why-choose-title">Pourquoi faire appel aux Serruriers de Montpellier ?</h2>
                            <div className="why-choose-accordion-container">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Pourquoi choisir Les Serruriers de Montpellier ?</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="why-choose-textual">
                                                Usures naturelles, intempéries, ou effractions, nous remplaçons manivelles, genouillères, tabliers, mécanismes, boîtiers, et sangles… Nous intervenons également sur la rénovation et la pose de nouveaux volets en aluminium, bois et pvc et sur les systèmes anti-effractions. Nous traitons tous types de volets : roulants, battants, pliants, et coulissants. Nos experts s’occupent également de la motorisation de vos volets pour un confort d’utilisation.
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>Quel est le prix d'une ouverture de porte ?</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="why-choose-textual">
                                                Nos prix sont fixés à l’avance : Aucune surprise au moment du règlement : les prix indiqués sur notre site internet sont les prix pratiqués ! Le dépannage est à 90 euros ttc le jour, 135 euros la nuit.
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>Quel est le délais d'intervention  ?</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="why-choose-textual">
                                                Nos serruriers interviennent chez vous dans la demi heure qui suit et vous assurent un devis avant la réalisation des travaux ainsi que la garantie de nos prestations.
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>Avis sur Google 4.7/5</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="why-choose-textual">
                                                La relation client est au cœur de notre métier, et votre avis nous intéresse. Nous avons récolté + de 600 avis et obtenus la note de 4.7/5.
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="fullpage-section" className="counter-section">
                    <div className="counter-section-container">
                        <div className="counter-section-content">
                            <div className="counter-item-container">
                                <h4 className="counter-item-number">10+</h4>
                                <div className="counter-item-text">années d'expérience</div>
                            </div>
                            <div className="counter-item-container">
                                <h4 className="counter-item-number">4.7/5</h4>
                                <div className="counter-item-text">sur Google Score</div>
                            </div>
                            <div className="counter-item-container">
                                <h4 className="counter-item-number">+200</h4>
                                <div className="counter-item-text">clients satisfaits</div>
                            </div>
                        </div>
                    </div>
                </section>

                <FrontFooter />
            </div>
        </div>
    )
}

export default VoletRoulant
