import React from 'react'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';

const FrontHeaderV2 = () => {
  return (
    <div>
        <Navbar key={`lg`} expand={`lg`} className="bg-body-tertiary mb-3 app-navbar">
            <Container className="app-navbar-container">
                <Navbar.Brand href="/" className="app-navbar-brand">
                    <img
                        src="/images/appImages/logo.png"
                        alt="KOKÙTANA"
                        className="app-logo"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${`lg`}`} className="app-navbar-toggle" />
                <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-${`lg`}`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-${`lg`}`}
                    placement="end"
                >
                    <Offcanvas.Header closeButton className="app-navbar-offset-header">
                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${`lg`}`} className="app-navbar-offset-title">
                            <img
                                src="/images/appImages/logo.png"
                                alt="KOKÙTANA"
                                className="offset-app-logo"
                            />
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body className="app-navbar-offset-body">
                        <Nav className="justify-content-start flex-grow-1 pe-3 app-navbar-nav">
                            <Nav.Link href="/" className="nav-link menu-link app-nav-link">Accueil</Nav.Link>
                            <Nav.Link href="/services/serrurier" className="nav-link menu-link app-nav-link">Serrurier</Nav.Link>
                            <Nav.Link href="/services/depannage-volet-roulant" className="nav-link menu-link app-nav-link">Volet roulant</Nav.Link>
                            <Nav.Link href="/services/porte-de-garage" className="nav-link menu-link app-nav-link">Porte garage</Nav.Link>
                            <Nav.Link href="/services/porte-blindee" className="nav-link menu-link app-nav-link">Porte blindée</Nav.Link>
                            {/* <NavDropdown
                                title="Dropdown"
                                id={`offcanvasNavbarDropdown-expand-${`lg`}`}
                            >
                                <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                                <NavDropdown.Item href="#action4">Another action</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action5">Something else here</NavDropdown.Item>
                            </NavDropdown> */}
                        </Nav>
                        <div className="header-btns-container">
                            <a href="mailto:contact@serrurier-pas-cher-montpellier.com" target="_blank" className="quotation-btn btn">Devis gratuit</a>
                            <a href="tel:06 44 96 24 01" target="_blank" className="call-me-btn btn">06 44 96 24 01</a>
                        </div>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
    </div>
  )
}


export default FrontHeaderV2
