import React, { useCallback, useEffect } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Homepage from './components/Frontend/Homepage/Homepage';
import Serrurier from './components/Frontend/Pages/Serrurier/Serrurier';
import VoletRoulant from './components/Frontend/Pages/VoletRoulant/VoletRoulant';
import Garage from './components/Frontend/Pages/Garage/Garage';
import PorteBlindee from './components/Frontend/Pages/PorteBlindee/PorteBlindee';

const App = () => {
  return (
    <div className="router-container">
        <HelmetProvider>
            <Routes>
                <Route path="/" element={<Homepage />} />
                <Route path="/services/serrurier" element={<Serrurier />} />
                <Route path="/services/depannage-volet-roulant" element={<VoletRoulant />} />
                <Route path="/services/porte-de-garage" element={<Garage />} />
                <Route path="/services/porte-blindee" element={<PorteBlindee />} />
            </Routes>
        </HelmetProvider>
        <a 
            href="https://www.google.com/maps/place/Ets+GUEZ+Urgence+D%C3%A9pannage+Plomberie+Serrurerie+Montpellier/@43.591486,3.391874,9z/data=!3m1!4b1!4m6!3m5!1s0x12b6ae8eae7bbe15:0xfc1b06f46fc3b70c!8m2!3d43.591486!4d3.391874!16s%2Fg%2F11g6wcs3js?entry=ttu&g_ep=EgoyMDI0MTExMS4wIKXMDSoASAFQAw%3D%3D" 
            className="goto-google-preview"
            target="_blank"
        >
            <img src="/images/appImages/badge-google.svg" alt="Les Serruriers de Montpellier 7j/7 24H/24" className="google-badge-image linkable" />
        </a>
        
        <div className="global-cta-btn-container">
            <a title="Appelez-nous" target="_blank" rel="noreferrer" href="tel:06 44 96 24 01" className="global-cta-btn call-btn">
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" className="app-global-phone-icon" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M4 4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4zm5 7a1 1 0 1 0-2 0 1 1 0 0 0 2 0zM1.807 4.734a.5.5 0 1 0-.884-.468A7.967 7.967 0 0 0 0 8c0 1.347.334 2.618.923 3.734a.5.5 0 1 0 .884-.468A6.967 6.967 0 0 1 1 8c0-1.18.292-2.292.807-3.266zm13.27-.468a.5.5 0 0 0-.884.468C14.708 5.708 15 6.819 15 8c0 1.18-.292 2.292-.807 3.266a.5.5 0 0 0 .884.468A7.967 7.967 0 0 0 16 8a7.967 7.967 0 0 0-.923-3.734zM3.34 6.182a.5.5 0 1 0-.93-.364A5.986 5.986 0 0 0 2 8c0 .769.145 1.505.41 2.182a.5.5 0 1 0 .93-.364A4.986 4.986 0 0 1 3 8c0-.642.12-1.255.34-1.818zm10.25-.364a.5.5 0 0 0-.93.364c.22.563.34 1.176.34 1.818 0 .642-.12 1.255-.34 1.818a.5.5 0 0 0 .93.364C13.856 9.505 14 8.769 14 8c0-.769-.145-1.505-.41-2.182z"></path></svg>
            </a>
            <a title="Ecrivez-nous" target="_blank" rel="noreferrer" href="mailto:contact@serrurier-montpellier.com" className="global-cta-btn quotation-btn">
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" className="app-global-phone-icon" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 19a6.995 6.995 0 0110-6.32V6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h8.08c-.05-.33-.08-.66-.08-1zM4 6l8 5 8-5v2l-8 5-8-5V6zm13.34 16l-3.54-3.54 1.41-1.41 2.12 2.12 4.24-4.24L23 16.34 17.34 22z"></path></svg>
            </a>
        </div>
    </div>
)
}

export default App;
