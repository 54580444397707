import React from 'react'
import AppSeo, { imageUrlBuilder } from '../../../../modules/AppSeo/AppSeo'
import FrontHeaderV2 from '../../../Shared/FrontHeader/FrontHeaderV2'
import FrontFooter from '../../../Shared/FrontFooter/FrontFooter'
import Slider from "react-slick";
import Accordion from 'react-bootstrap/Accordion';

const PorteBlindee = () => {
    var settings = {
        dots: true,
        infinite: true,
        autoPlay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    };

    return (
        <div className="page app-component-container">
            <AppSeo 
                title="Serrurier Montpellier | Prix fixé à l'avance - 24h/24h"
                author="Sadio Sangharé" 
                content="Serrurier Montpellier | Prix fixé à l'avance - 24h/24h depuis 2010 ✔️Ouverture de porte 90€ ✔️Urgence 7j/7 ✔️Devis Gratuit ✔️ Agréé Assurance ⭐ 4.7/5 ☆ sur Google" 
                image="/images/appImages/seo-image.png" 
            />


            <FrontHeaderV2 />
            <div className="page-content-container">
                <section id="page-cover" className="page-cover-container porte-blindee">
                    <div className="homepage-cover-resume-container">
                        <h1 className="oh-page-cover-title">Porte blindée, blindage de porte</h1>
                        <h2 className="oh-page-title-resume">Installateur de porte blindée maison, appartement, cave, accès collectif</h2>
                        <h2 className="oh-page-title-resume">Agréé assurances</h2>
                    </div>
                    <div className="page-background-overlay"></div>
                </section>

                <section className="container oh-app-page-container">
                    <div className="service-type-content-body">
                        <div className="row service-type-row">
                            <div className="col-lg-3 service-type-col">
                                <div className="service-type-card-container">
                                    <img src="https://accord-assistance.fr/wp-content/uploads/2021/11/porte-blindee-certifiee-a2p.svg" alt="Porte blindée certifiée A2P montpellier" className="service-type-image-icon" />
                                    <h3 className="service-type-card-title">Porte blindée certifiée A2P</h3>
                                    <div className="service-type-card-body">
                                        <div className="service-type-listing-container">
                                            <ul className="service-type-listing">
                                                <li>Porte blindée maison</li>
                                                <li>Porte blindée appartement</li>
                                                <li>Blindage de porte</li>
                                                <li>Porte blindée cave</li>
                                                <li>Porte blindée accès collectif</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 service-type-col">
                                <div className="service-type-card-container">
                                    <img src="https://accord-assistance.fr/wp-content/uploads/2021/11/serrure-haute-securite-porte-blindee.svg" alt="Classement des portes blindées montpellier" className="service-type-image-icon" />
                                    <h3 className="service-type-card-title">Classement des portes blindées</h3>
                                    <div className="service-type-card-body">
                                        <div className="service-type-listing-container">
                                            <ul className="service-type-listing">
                                                <li>A2P BP1 : porte blindée équipée d’une serrure A2P*</li>
                                                <li>A2P BP2 : porte blindée équipée d’une serrure A2P **</li>
                                                <li>A2P BP3 : porte blindée équipée d’une serrure A2P ***</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 service-type-col">
                                <div className="service-type-card-container">
                                    <img src="https://accord-assistance.fr/wp-content/uploads/2021/11/composition-porte-blindee.svg" alt="Composition d'une porte blindée montpellier" className="service-type-image-icon" />
                                    <h3 className="service-type-card-title">Composition d'une porte blindée</h3>
                                    <div className="service-type-card-body">
                                        <div className="service-type-listing-container">
                                            <ul className="service-type-listing">
                                                <li>Le vantail, la partie ouvrante de la porte</li>
                                                <li> Le bâti, la partie fixé au mur qui encadre et porte la porte</li>
                                                <li>La serrure multipoint obligatoirement</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 service-type-col">
                                <div className="service-type-card-container">
                                    <img src="https://accord-assistance.fr/wp-content/uploads/2021/11/avantage-porte-blindee.svg" alt="réparation volet roulant montpellier" className="service-type-image-icon" />
                                    <h3 className="service-type-card-title">Avantages d'une porte blindée</h3>
                                    <div className="service-type-card-body">
                                        <div className="service-type-listing-container">
                                            <ul className="service-type-listing">
                                                <li>Résistance aux effractions</li>
                                                <li>Isolation phonique</li>
                                                <li>Isolation thermique</li>
                                                <li>Coupe feu</li>
                                                <li>Esthétique et habillage</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="service-textual-container">
                        <div className="service-textual-content-body">
                            <div className="row promo-row">
                                <div className="col-lg-3 promo-image-col">
                                    <div className="promo-image-container">
                                        <img src="https://accord-assistance.fr/wp-content/uploads/2021/09/shutterstock_567804673.jpg" alt="Serruriers de Montpellier pas cher" className="promo-image" />
                                    </div>
                                </div>
                                <div className="col-lg-9 promo-text-col">
                                    <div className="promo-text-container">
                                        <h4 className="promo-text-title">Équipez-vous d'une porte blindée !</h4>
                                        <h5 className="promo-text-second-title">Intervention en urgence 24h/24 7J/7</h5>
                                        <div className="promo-text-content-body">
                                            <p>Vous souhaitez partir en vacances en toute tranquillité ou vous sentir serein lorsque vous n'etes pas à domicile ? Faites appel à un nos serruriers pour l’installation d’une porte blindée. Nous vous avons sélectionné des portes blindées certifiées par le CNPP pour être en conformité avec les normes anti-effraction.</p>
                                            <p>La porte d’entrée est un endroit sensible et 80% des cambrioleurs tentent de rentrer par cette porte. Une porte blindée offre beaucoup plus de sécurité qu’une porte simple, et fera la différence entre un cambriolage et une tentative d’effraction.</p>
                                        </div>
                                        <div className="service-promo-with-slide-slider-container">
                                            <div className="reactif-slide-container"> 
                                                <Slider {...settings}>
                                                    <div className="reactif-infos-slide-item-container">
                                                        <div className="reactif-infos-slide-item-content">
                                                            <img decoding="async" 
                                                                src="https://accord-assistance.fr/wp-content/uploads/2022/01/serrurier-agree-assurance.svg" 
                                                                className="reactif-infos-slide-item-icon" 
                                                                alt="serrurier agréé par les assurances" 
                                                            />
                                                            <div className="reactif-infos-slide-item-textual-container">
                                                                <h4 className="reactif-infos-slide-item-title mb-2">Tarifs compétitifs agréés assurances</h4>
                                                                <div className="reactif-infos-slide-item-text">
                                                                    <p>
                                                                        Notre grille tarifaire est validée par de nombreuses compagnies d’assurances. Tous nos tarifs incluent le déplacement, la main d’œuvre et les fournitures.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="reactif-infos-slide-item-container">
                                                        <div className="reactif-infos-slide-item-content">
                                                            <img decoding="async" 
                                                                src="https://accord-assistance.fr/wp-content/uploads/2022/01/serrurier-urgence.svg" 
                                                                className="reactif-infos-slide-item-icon" 
                                                                alt="serrurier agréé par les assurances" 
                                                            />
                                                            <div className="reactif-infos-slide-item-textual-container">
                                                                <h4 className="reactif-infos-slide-item-title mb-2">Contact client permanent</h4>
                                                                <div className="reactif-infos-slide-item-text">
                                                                    <p>
                                                                        Nous sommes là pour vous assister par téléphone de la prise de rendez-vous jusqu’à la réalisation des travaux, afin de répondre à toutes vos questions.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="reactif-infos-slide-item-container">
                                                        <div className="reactif-infos-slide-item-content">
                                                            <img decoding="async" 
                                                                src="https://accord-assistance.fr/wp-content/uploads/2022/01/serrurier-devis-gratuit.svg" 
                                                                className="reactif-infos-slide-item-icon" 
                                                                alt="serrurier devis gratuit sans engagement" 
                                                            />
                                                            <div className="reactif-infos-slide-item-textual-container">
                                                                <h4 className="reactif-infos-slide-item-title mb-2">Devis gratuit</h4>
                                                                <div className="reactif-infos-slide-item-text">
                                                                    <p>
                                                                    Nous intervenons chez vous pour établir un devis gratuit sans engagement en tenant compte des spécificités de votre domicile et de votre budget.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="reactif-infos-slide-item-container">
                                                        <div className="reactif-infos-slide-item-content">
                                                            <img decoding="async" 
                                                                src="https://accord-assistance.fr/wp-content/uploads/2022/01/serrurier-intervention.svg" 
                                                                className="reactif-infos-slide-item-icon" 
                                                                alt="serrurier intervention 7j/7 24h/24" 
                                                            />
                                                            <div className="reactif-infos-slide-item-textual-container">
                                                                <h4 className="reactif-infos-slide-item-title mb-2">Disponible 7j/7 24h/24</h4>
                                                                <div className="reactif-infos-slide-item-text">
                                                                    <p>
                                                                    Notre équipe de serruriers menuisiers est disponible tous les jours de la semaine, y compris les jours fériés, 24h/24.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Slider> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section id="reactif-infos-section">
                        <div className="reactif-infos-container">
                            <div className="row reactif-infos-row">
                                <div className="col-lg-6 reactif-infos-col">
                                    <div className="reactif-infos-resume-container">
                                        <h3 className="credo-title">Pourquoi installer une porte blindée ?</h3>
                                        <div className="reactif-infos-resume">
                                            <p>Certaines compagnies d’assurance peuvent prendre en compte l’installation d’une porte blindée dans leurs contrats d’assurance habitation. La porte d’entrée est depuis toujours le principal point d’accès pour des malfaiteurs.</p>
                                            <p>Une porte d’entrée classique à une faible résistance aux agressions d’un cambrioleurs. A la vue d’une porte blindée, il se peut que le malfaiteur ne soit pas équipé pour l’ouvrir ! En plus de se savoir en sécurité chez vous (ce qui n’a pas de prix), la porte blindée offre une isolation phonique et thermique.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 reactif-slide-col">
                                    <div className="reactif-slide-container"> 
                                        <Slider {...settings}>
                                            <div className="reactif-infos-slide-item-container">
                                                <div className="reactif-infos-slide-item-content">
                                                    <img decoding="async" 
                                                        src="https://accord-assistance.fr/wp-content/uploads/2022/01/serrurier-agree-assurance.svg" 
                                                        className="reactif-infos-slide-item-icon" 
                                                        alt="serrurier agréé par les assurances" 
                                                    />
                                                    <div className="reactif-infos-slide-item-textual-container">
                                                        <h4 className="reactif-infos-slide-item-title mb-2">Tarifs compétitifs agréés assurances</h4>
                                                        <div className="reactif-infos-slide-item-text">
                                                            <p>
                                                                Notre grille tarifaire est validée par de nombreuses compagnies d’assurances. Tous nos tarifs incluent le déplacement, la main d’œuvre et les fournitures.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="reactif-infos-slide-item-container">
                                                <div className="reactif-infos-slide-item-content">
                                                    <img decoding="async" 
                                                        src="https://accord-assistance.fr/wp-content/uploads/2022/01/serrurier-urgence.svg" 
                                                        className="reactif-infos-slide-item-icon" 
                                                        alt="serrurier agréé par les assurances" 
                                                    />
                                                    <div className="reactif-infos-slide-item-textual-container">
                                                        <h4 className="reactif-infos-slide-item-title mb-2">Contact client permanent</h4>
                                                        <div className="reactif-infos-slide-item-text">
                                                            <p>
                                                                Nous sommes là pour vous assister par téléphone de la prise de rendez-vous jusqu’à la réalisation des travaux, afin de répondre à toutes vos questions.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="reactif-infos-slide-item-container">
                                                <div className="reactif-infos-slide-item-content">
                                                    <img decoding="async" 
                                                        src="https://accord-assistance.fr/wp-content/uploads/2022/01/serrurier-devis-gratuit.svg" 
                                                        className="reactif-infos-slide-item-icon" 
                                                        alt="serrurier devis gratuit sans engagement" 
                                                    />
                                                    <div className="reactif-infos-slide-item-textual-container">
                                                        <h4 className="reactif-infos-slide-item-title mb-2">Devis gratuit</h4>
                                                        <div className="reactif-infos-slide-item-text">
                                                            <p>
                                                            Nous intervenons chez vous pour établir un devis gratuit sans engagement en tenant compte des spécificités de votre domicile et de votre budget.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="reactif-infos-slide-item-container">
                                                <div className="reactif-infos-slide-item-content">
                                                    <img decoding="async" 
                                                        src="https://accord-assistance.fr/wp-content/uploads/2022/01/serrurier-intervention.svg" 
                                                        className="reactif-infos-slide-item-icon" 
                                                        alt="serrurier intervention 7j/7 24h/24" 
                                                    />
                                                    <div className="reactif-infos-slide-item-textual-container">
                                                        <h4 className="reactif-infos-slide-item-title mb-2">Disponible 7j/7 24h/24</h4>
                                                        <div className="reactif-infos-slide-item-text">
                                                            <p>
                                                            Notre équipe de serruriers menuisiers est disponible tous les jours de la semaine, y compris les jours fériés, 24h/24.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Slider> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div className="service-volet-type-container">
                        <div className="service-volet-type-content-body">
                            <div className="service-volet-question-container">
                                <div className="service-volet-question-row">
                                    <h4 className="servicevolet-question-title">Qu'est ce qu'une porte blindée ?</h4>
                                    <div className="service-volet-question-text-container">
                                        <p>Une porte blindée est spécialement conçue pour assurer une sécurité optimale face aux tentatives d’effraction. Fabriquée avec des matériaux résistants et une armature métallique solide, elle intègre des systèmes de verrouillage sophistiqués. À la différence des portes standards, elle bénéficie de multiples couches de protection, incluant des plaques en acier, des charnières renforcées et un cadre robuste. De plus, elle est équipée d’une serrure de haute sûreté, souvent dotée de plusieurs points de verrouillage pour garantir une défense maximale.</p>
                                    </div>
                                </div>
                                <div className="row service-volet-response-row">
                                    <div className="service-volet-response-col col-lg-9">
                                        <div className="service-volet-response-content-body">
                                            <h5 className="service-volet-response-title">Composition d'une porte blindée</h5>
                                            <div className="service-volet-response-listing-container">
                                                <ul className="service-volet-response-listing">
                                                    <li>Panneau principal : La base de la porte est souvent fabriquée en acier ou en alliage métallique très résistant. Elle peut être renforcée avec plusieurs couches supplémentaires de métal et de matériaux isolants pour une meilleure sécurité.</li>
                                                    <li>Renfort interne : À l’intérieur de la porte, des plaques d’acier sont intégrées pour offrir une protection accrue contre les tentatives d’effraction.</li>
                                                    <li>Serrure à points multiples : Cette serrure de haute sécurité est équipée de plusieurs points de verrouillage (3, 5 ou 7), qui se verrouillent le long de la porte pour une sécurité optimale.</li>
                                                    <li>Cylindre sécurisé : Élément clé de la serrure, le cylindre (ou barillet) est conçu pour résister aux techniques de perçage, de crochetage et, dans certains cas, à la copie de clés.</li>
                                                    <li>Dormant renforcé : Le cadre de la porte, également appelé dormant, est renforcé avec de l’acier pour garantir une fixation solide entre la porte et le mur, améliorant ainsi la résistance aux effractions.</li>
                                                    <li>Charnières robustes : Les charnières sont renforcées pour empêcher toute tentative de délogement de la porte de son cadre.</li>
                                                    <li>Protection anti-pince : Une cornière métallique, fixée entre la porte et le cadre, empêche les cambrioleurs d’insérer un outil pour forcer l’ouverture.</li>
                                                    <li>Isolation thermique et acoustique : Outre la sécurité, la porte blindée est souvent équipée de matériaux isolants qui réduisent les nuisances sonores et les pertes thermiques.</li>
                                                    <li>Joints d’étanchéité : Ces joints renforcent l’isolation de la porte, empêchant l’infiltration d’air ou de bruit, tout en contribuant à améliorer l’efficacité énergétique.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="service-volet-response-image-col col-lg-3">
                                        <div className="service-volet-image-content-body">
                                            <img src="https://accord-assistance.fr/wp-content/uploads/2021/11/porte-blindee-appartement-RmeticAbsolu-1-copie.png" alt="réparation de porte garage à montpellier" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="service-porte-blindee-type-container">
                        <div className="service-porte-blindee-type-content-body">
                            <div className="service-volet-question-row">
                                <h4 className="servicevolet-question-title">Nos portes blindées pour maison</h4>
                            </div>
                            <div className="service-porte-blindee-item-row row">
                                <div className="col-lg-6 service-porte-blindee-item-col image-col">
                                    <div className="service-porte-blindee-item-image-container">
                                        <img src="https://accord-assistance.fr/wp-content/uploads/2021/11/devis-gratuit-installation-porte-blindee.jpg" alt="devis gratuit installation porte blindée" className="service-porte-blindee-item-image" />
                                    </div>
                                </div>
                                <div className="col-lg-6 porte-blindee-item-col text-col">
                                    <div className="porte-blindee-item-text-container">
                                        <h4 className="porte-blindee-item-title">Porte blindée maison</h4>
                                        <div className="service-porte-blindee-item-text-container">
                                            <p className="qodef-m-text">Vous vous avons sélectionné des <strong>portes blindées certifiées par le CNN</strong> pour être en conformité avec les normes anti-effraction. Une porte d’entrée dite classique à une faible résistance aux agressions d’un cambrioleur sans même que votre voisinage soit alerté.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="service-porte-blindee-type-container">
                        <div className="service-porte-blindee-type-content-body">
                            <div className="service-volet-question-row">
                                <h4 className="servicevolet-question-title">Nos portes blindées pour appartement</h4>
                            </div>
                            <div className="service-porte-blindee-item-row row">
                                <div className="col-lg-6 service-porte-blindee-item-col image-col">
                                    <div className="service-porte-blindee-item-image-container">
                                        <img src="https://accord-assistance.fr/wp-content/uploads/2021/11/porte-blindee-appartement.jpg" alt="devis gratuit installation porte blindée" className="service-porte-blindee-item-image" />
                                    </div>
                                </div>
                                <div className="col-lg-6 porte-blindee-item-col text-col">
                                    <div className="porte-blindee-item-text-container">
                                        <h4 className="porte-blindee-item-title">Porte blindée maison</h4>
                                        <div className="service-porte-blindee-item-text-container">
                                            <p className="qodef-m-text">Vous vous avons sélectionné des <strong>portes blindées certifiées par le CNN</strong> pour être en conformité avec les normes anti-effraction. Une porte d’entrée dite classique à une faible résistance aux agressions d’un cambrioleur sans même que votre voisinage soit alerté.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className="why-choose-container">
                        <div className="why-choose-content-body">
                            <h2 className="why-choose-title">Pourquoi faire appel aux Serruriers de Montpellier ?</h2>
                            <div className="why-choose-accordion-container">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Pourquoi choisir Les Serruriers de Montpellier ?</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="why-choose-textual">
                                                Avec plus de 15 ans d’expérience, nous avons su nous démarquer des serruriers véreux qui sévisse dans le milieu. Nous sommes agréés assurances depuis de nombreuses années et pratiquons une grille unique et sans surprise. 
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>Quel est le prix d'une ouverture de porte ?</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="why-choose-textual">
                                                Pour une ouverture de porte claquée le tarif est de 90 euros ttc le jour, 135 euros la nuit et pas 1 euros de plus !
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>Quel est le délais d'intervention  ?</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="why-choose-textual">
                                                Nos serruriers interviennent dans la demi-heure qui suit votre appel !
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="fullpage-section" className="counter-section">
                    <div className="counter-section-container">
                        <div className="counter-section-content">
                            <div className="counter-item-container">
                                <h4 className="counter-item-number">10+</h4>
                                <div className="counter-item-text">années d'expérience</div>
                            </div>
                            <div className="counter-item-container">
                                <h4 className="counter-item-number">4.7/5</h4>
                                <div className="counter-item-text">sur Google Score</div>
                            </div>
                            <div className="counter-item-container">
                                <h4 className="counter-item-number">+200</h4>
                                <div className="counter-item-text">clients satisfaits</div>
                            </div>
                        </div>
                    </div>
                </section>

                <FrontFooter />
            </div>
        </div>
    )
}

export default PorteBlindee;
