import React, { useEffect, useState } from "react";
import "./FrontFooter.css";
import { NavLink } from "react-router-dom";
import { AiFillInstagram } from "react-icons/ai";
import { BsTwitterX } from "react-icons/bs";

const FrontFooter = () => {
    const date = new Date();
    
    return (
        <footer className="footer-component">
            <div className="content-head-footer">
                <div className="container-app-kokutana">
                    <div className="row">
                        <div className="col-lg-12 col-footer mb-3">
                            <div className="footer-grid">
                                <img
                                    src="/images/appImages/logo.png"
                                    alt="logo"
                                    className="logo-app-footer"
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-footer mb-3">
                            <div className="content-info-contact-footer">
                                <h6 className="footer-title">A Propos</h6>
                                <div className="footer-menu-container">
                                    <ul className="footer-link-ul">
                                        <li>
                                            <NavLink to="/services/serrurier" className="fotter-menu-link">Nos tarifs</NavLink>
                                        </li>
                                        {/* <li>
                                            <NavLink to="/blog" className="footer-menu-link">Blog serrurier</NavLink>
                                        </li> */}
                                        <li>
                                            <a href="mailto:contact@serrurier-pas-cher-montpellier.com" target="_blank" className="footer-menu-link">Demander un devis</a>
                                        </li>
                                        <li>
                                            <a href="tel:06 44 96 24 01" target="_blank" className="footer-menu-link">Nous appeler</a>
                                        </li>
                                    </ul>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-lg-4 col-footer mb-3">
                            <div className="content-info-contact-footer">
                                <h6 className="footer-title">Nos services</h6>
                                <div className="footer-menu-container">
                                    <ul className="footer-link-ul">
                                        <li>
                                            <NavLink to="/services/serrurier" className="fotter-menu-link">Serrurerie</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/services/depannage-volet-roulant" className="footer-menu-link">Volet roulant</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/services/porte-de-garage" className="footer-menu-link">Porte garage</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/services/porte-blindee" className="footer-menu-link">Porte blindée</NavLink>
                                        </li>
                                    </ul>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content-end-footer">
                <div className="content-copyright-footer">
                    <p className="copyright mb-0">Copyright © Serruriers de Montpellier {date.getFullYear()}</p>
                    {/* <p className="copyright mb-0">Conditions d'Utilisation</p> */}
                </div>
                {/* <div className="container-social-media">
                    <p className="title-social-media-footer">
                        Suivez sur les réseaux sociaux
                    </p>
                    <div className="Social-media-to-share">
                        <a href="/" target="_blank" className="twitter">
                            <BsTwitterX className="share-icon-social-media-item" />
                        </a>
                        <a href="https://www.linkedin.com/showcase/kok%C3%B9tana-by-volkeno/" target="_blank" className="linkedin">
                            <GrLinkedinOption className="share-icon-social-media-item" />
                        </a>
                        <a href="https://www.instagram.com/kokutana_by_volkeno/" target="_blank" className="instagram">
                            <AiFillInstagram className="share-icon-social-media-item" />
                        </a>
                    </div>
                </div> */}
            </div>
        </footer>
    );
};

export default FrontFooter;
