import React from 'react';
import { Helmet } from 'react-helmet-async';

const AppSeo = ({title, author, content, image}: any) => {
    return (
        <Helmet>
            { /* Standard metadata tags */ }
            <title>{title}</title>
            <meta
                name='description'
                content={ content }
            />
            { /* End standard metadata tags */ }

            { /* Facebook tags */ }
            <meta
                property="og:type"
                content="article"
            />
            <meta
                property="og:title"
                content={title}
            />
            <meta
                property="og:description"
                content={ content }
            />
            { /* End Facebook tags */ }

            { /* Twitter tags */ }
            <meta
                name="twitter:creator"
                content={author}
            />
            <meta
                name="twitter:card"
                content="article"
            />
            <meta
                name="twitter:title"
                content={title}
            />
            <meta
                name="twitter:description"
                content={ content }
            />
            <meta
                name="twitter:title"
                property="twitter:title"
                content="Saabal"
            />
            <meta
                property="twitter:description"
                name="twitter:description"
                content={content}
            />
            <meta
                property="twitter:image"
                name="twitter:image"
                content={image}
            />
            <meta
                property="twitter:card"
                name="twitter:card"
                content="summary_large_image"
            />
            { /* End Twitter tags */ }

            <meta
                name="description"
                content={content}
            />
            <meta
                property="og:title"
                name="og:title"
                content={title}
            />
            <meta
                property="og:description"
                name="og:description"
                content={content}
            />
            <meta
                property="og:image"
                name="og:image"
                content={image}
            />
            <meta
                property="og:title"
                name="og:title"
                content={title}
            />
            <meta
                property="og:description"
                name="og:description"
                content={content}
            />
            <meta
                property="og:image"
                name="og:image"
                content={image}
            />
        </Helmet>
    )
}

export default AppSeo

export const imageUrlBuilder = (image_path:any) => {
    return `${process.env.MIX_FRONTEND_BASE_URL}${image_path}`
}
